// auth.js
export const LOGOUT = 'LOGOUT'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_USERID = 'SET_USERID'
export const SET_ACTIVE = 'SET_ACTIVE'
export const SET_LOAN_SUBMITTED = 'SET_LOAN_SUBMITTED'
export const SET_LOAN_FORM_STATE = 'SET_LOAN_FORM_STATE'
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
export const SET_REJECT_CODE = 'SET_REJECT_CODE'

export const FETCH_PROVINCE = 'FETCH_PROVINCE'

// lang.js
export const SET_LOCALE = 'SET_LOCALE'

//register.js
export const SAVE_REGISTER_INFO = 'SAVE_REGISTER_INFO'
export const SAVE_OTP_CODE = 'SAVE_OTP_CODE' //for testing onley need to delete
export const SAVE_PINCODE = 'SAVE_PINCODE'
export const REGISTER_RESET = 'REGISTER_RESET'

//loan.js
export const SET_LOANSTATE = 'SET_LOANSTATE'

//imageControl.js
export const SAVE_UPLOADED_DOCUMENT = 'SAVE_UPLOADED_DOCUMENT'

//test.js
export const SET_APPOINTMENT = 'SET_APPOINTMENT'

//notificaiton js
export const SET_COUNT = 'SET_COUNT'
